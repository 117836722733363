import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server
function videoRecordConfig(domainName, ossBucket, stId) {
  let data = { domainName, ossBucket, stId };
  return request({
    url: `${server}/media/OssVideo/videoRecordConfig`,
    method: 'post',
    data,
  });
}

function getSiteVideoRecordConfig(stId) {
  let data = { stId };
  return request({
    url: `${server}/media/OssVideo/queryVideoRecordConfig?stId=${stId}`,
    method: 'GET',
    data,
  });
}

export { videoRecordConfig, getSiteVideoRecordConfig };
