<template>
  <div class="main">
    <div class="site">
      <el-form :disabled="isDisabled" class="site-information-form" label-position="left" label-width="170px" ref="SiteInfo" :model="siteInfo" :rules="rules">
        <div class="one">
          <div class="item">
            <el-row class="title">
              <el-col :span="24">
                <p>基本信息</p>
              </el-col>
            </el-row>
          </div>
          <div class="content">
            <el-row>
              <el-col :span="8">
                <el-form-item label="编号">
                  <el-input v-model="siteInfo.siteID" :disabled="stId ? true : false" placeholder="ST_00000017"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="名称" prop="siteName">
                  <el-input v-model="siteInfo.siteName" placeholder="请输入站点名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="归属项目" prop="PID">
                  <!-- :disabled="stId ? true : false" -->
                  <el-select v-model="siteInfo.PID">
                    <el-option v-for="project in projectList" :key="project.projectId" :value="project.projectId" :label="project.projectName"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="地址" prop="siteAddress">
                  <el-input v-model="siteInfo.siteAddress" placeholder="请输入站点地址"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="地理位置" prop="siteLocation">
                  <el-input v-model="siteInfo.siteLocation" placeholder="121.1234567,31.12345678"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="海拔高度" prop="siteAltitude">
                  <el-input v-model="siteInfo.siteAltitude" placeholder="请输入海拔高度（单位：米）"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="类型" prop="siteMode">
                  <el-select v-model.number="siteInfo.siteMode">
                    <el-option v-for="model in SITEMODEL" :key="model.value" :label="model.label" :value="model.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="飞行模式" prop="siteSFMode">
                  <el-select v-model="siteInfo.siteSFMode">
                    <el-option :value="1" label="孤岛模式"></el-option>
                    <el-option :value="2" label="跳棋模式"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="siteInfo.siteSFMode == 2">
                <el-form-item label="飞行方式" prop="flightWay">
                  <el-select v-model="siteInfo.flightWay">
                    <el-option :value="1" label="普通巡航"></el-option>
                    <el-option :value="2" label="精细化飞行"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="企业组织" prop="CPID" v-if="isSelectCPID">
                  <el-select v-model="siteInfo.CPID" @change="getProjectList([siteInfo.CPID])">
                    <el-option v-for="cpItem in CPList" :key="cpItem.cpId" :value="cpItem.cpId" :label="cpItem.cpName"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="one">
          <div class="item">
            <el-row class="title">
              <el-col :span="24">
                <p>配置信息</p>
              </el-col>
            </el-row>
          </div>
          <div class="content">
            <el-row>
              <el-col :span="8">
                <el-form-item label="无人机返航高度" prop="siteRHAltitude">
                  <el-input v-model="siteInfo.siteRHAltitude" placeholder="请输入无人机返航高度（单位：米）"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="无人机降落悬停高度" prop="siteRHHoverAltitude">
                  <el-input v-model="siteInfo.siteRHHoverAltitude" :min="5" placeholder="请输入无人机降落悬停高度"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="无人机降落朝向" prop="siteRHHeading">
                  <el-input v-model="siteInfo.siteRHHeading" :min="-180" :max="180" placeholder="请输入无人机降落朝向"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="无人机备降位置" prop="siteOptionLocation" required>
                  <el-input v-model="siteInfo.siteOptionLocation" placeholder="121.1234567,31.12345678"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="无人机备降悬停高度" prop="siteOptionHoverAltitude">
                  <el-input v-model="siteInfo.siteOptionHoverAltitude" :min="-180" :max="180" placeholder="请输入无人机备降悬停高度"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="无人机备降点朝向" prop="siteOptionHeading">
                  <el-input v-model="siteInfo.siteOptionHeading" :min="-180" :max="180" placeholder="请输入无人机备降点朝向"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="无人机备降高度" prop="siteOptionAltitude">
                  <el-input v-model="siteInfo.siteOptionAltitude" :min="-180" :max="180" placeholder="请输入无人机备降高度"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="无人机安全位置" prop="siteSafeLocation">
                  <el-input v-model="siteInfo.siteSafeLocation" :min="-180" :max="180" placeholder="请输入无人机安全位置"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实时监控播放配置" prop="videoPullAddr">
                  <el-input v-model="siteInfo.videoPullAddr" placeholder="play3.skysys.cn"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="实时监控推流配置" prop="videoPushAddr">
                  <el-input v-model="siteInfo.videoPushAddr" placeholder="stream3.skysys.cn"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="stId">
              <el-col :span="24">
                <p class="p-show">实时监控播放FLV地址: {{ showPlayFlv }}</p>
              </el-col>
            </el-row>
            <el-row v-if="stId">
              <el-col :span="24">
                <p class="p-show">实时监控推流RTMP地址: {{ showPushRTMP }}</p>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="one" v-if="stId">
          <el-tabs v-model="activeTab" type="card" @tab-click="handleTabClick">
            <el-tab-pane label="无人机" name="uav">
              <el-form v-if="siteInfo.UAVInfo" label-position="left" label-width="100px">
                <el-row>
                  <el-col :span="8">
                    <p>编号：{{ siteInfo.UAVInfo.UAVID }}</p>
                  </el-col>
                  <el-col :span="8">
                    <p>名称：{{ siteInfo.UAVInfo.UAVName }}</p>
                  </el-col>
                  <el-col :span="8">
                    <p>类型：{{ siteInfo.UAVInfo.model }}</p>
                  </el-col>
                </el-row>
                <!-- <el-row>
                  <el-col :span="8">
                    <p>SD卡容量：{{ SDRemainingCapacity }}</p>
                  </el-col>
                  <el-col :span="8">
                    <p>无人机型号：{{ siteInfo.UAVInfo.model }}</p>
                  </el-col>
                </el-row> -->
                <el-row>
                  <el-col :span="24">
                    <p>实时监控播放FLV地址：{{ siteInfo.UAVInfo.UAVFLVURL }}</p>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <p>实时监控播放RTMP地址：{{ siteInfo.UAVInfo.UAVRTMPURL }}</p>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <p>实时监控推流RTMP地址：{{ siteInfo.UAVInfo.UAVPushURL }}</p>
                  </el-col>
                </el-row>
              </el-form>
              <p v-else>暂无无人机信息</p>
            </el-tab-pane>
            <el-tab-pane label="机库" name="hive">
              <el-form class="tabs-content" v-if="siteInfo.hiveInfo" label-position="left" label-width="100px">
                <el-row>
                  <el-col :span="8">
                    <p>编号：{{ siteInfo.hiveInfo.hiveID }}</p>
                  </el-col>
                  <el-col :span="8">
                    <p>名称：{{ siteInfo.hiveInfo.hiveName }}</p>
                  </el-col>
                  <!-- <el-col :span="8">
                    <p>机库型号：{{ siteInfo.hiveInfo.hiveModel }}</p>
                  </el-col> -->
                  <el-col :span="8">
                    <p>类型：{{ siteInfo.hiveInfo.hiveType == 1 ? '固定' : '移动' }}</p>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="16">
                    <p>实时监控播放FLV地址：{{ siteInfo.hiveInfo.hiveFLVURL }}</p>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="16">
                    <p>实时监控推流RTMP地址：{{ siteInfo.hiveInfo.hivePushURL }}</p>
                  </el-col>
                </el-row>
              </el-form>

              <p v-else>暂无机库信息</p>
            </el-tab-pane>

            <el-tab-pane v-if="false" label="录制配置" name="media">
              <el-form class="tabs-content" label-position="left" label-width="100px" ref="mediaForm" :model="media" :rules="rules2">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="播流域名" prop="domainName">
                      <el-input v-model="media.domainName" placeholder="请输入播流域名"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="oss仓库" prop="ossBucket">
                      <el-input v-model="media.ossBucket" placeholder="请输入存储oss仓库"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4" style="margin-left: 10px">
                    <el-button type="primary" @click="setVideoConfig">配置</el-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
            <el-tab-pane v-if="false" label="媒体数据储存配置" name="media">
              <el-form class="tabs-content" label-position="left" label-width="100px" ref="mediaForm" :model="media" :rules="rules2">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="站点编号" prop="domainName">
                      <el-input v-model="media.domainName" placeholder="请输入播流域名"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="企业编号" prop="ossBucket">
                      <el-input v-model="media.ossBucket" placeholder="请输入存储oss仓库"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col label="企业编号">
                    <el-button type="primary" @click="setVideoConfig">配置</el-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
            <!-- v-if="bucketConfig.ossConfig && bucketConfig.ossConfig.bucketName" -->
            <el-tab-pane label="FTP资源储存配置" name="mediaBucket">
              <el-form size="mini" label-position="left" label-width="140px" :rules="bucketRule" :model="bucketConfig.ftpConfig">
                <el-form-item label="FTP服务器IP" prop="ftpAddr">
                  <el-input v-model="bucketConfig.ftpConfig.ftpAddr"></el-input>
                </el-form-item>
                <el-form-item label="FTP服务器密码" prop="ftpPassword">
                  <el-input v-model="bucketConfig.ftpConfig.ftpPassword" type="password" show-password></el-input>
                </el-form-item>
                <el-form-item label="FTP服务器端口号" prop="ftpPort">
                  <el-input v-model.number="bucketConfig.ftpConfig.ftpPort"></el-input>
                </el-form-item>
                <el-form-item label="FTP服务器用户名" prop="ftpUsername">
                  <el-input v-model="bucketConfig.ftpConfig.ftpUsername"></el-input>
                </el-form-item>
                <el-form-item label="FTP服务协议">
                  <el-select v-model="bucketConfig.ftpConfig.protocol">
                    <el-option v-for="item in agreements" :value="item" :label="item" :key="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="加密方式" v-if="bucketConfig.ftpConfig.protocol === 'FTP'">
                  <el-select v-model="bucketConfig.ftpConfig.encryption">
                    <el-option v-for="item in encryptions" :value="item.value" :label="item.label" :key="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="字符集">
                  <el-select v-model="bucketConfig.ftpConfig.character">
                    <!-- <el-option v-for="item in character" :value="item.value" :label="item.label" :key="item.value"></el-option> -->
                    <el-option value="UTF-8" label="UTF-8"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button size="mini" @click="updateMdeiaBucketConfig">确定</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div></div>
      </el-form>

      <div class="btns">
        <el-row>
          <el-col :span="24">
            <div style="display: flex; width: 200px; margin: auto; margin-top: 20px">
              <el-button type="primary" @click="editSiteInformation()">确定</el-button>
              <el-button @click="$router.go(-1)">返回</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getSiteDetail, addSiteInformation, updateSiteInformation } from '@/api/site';
import { myApprovalTaskList, getApprovalTaskDetail, undoMyApprovalTask, getCorporationBySelf, getProjectBycpIds, getStationsByProjects, addApprovalTask, getUserRole, updateApprovalTask } from '@/api/accraditation';
import { getMediaConfig, updateMediaBucketConfig } from '@/api/media/index.js';
import { queryProjectListByCpID } from '@/api/project.js';
import { ilsite, isLnglat, isLnglatByValue, isSiteId, isFLoat, isFLoatByValue, hoverAltitude } from '@/utils/rules';
import { getUserInformation } from '@/utils/auth.js';
import { SITEMODEL } from '@/utils/form.config';
import statusInclude from '@/utils/statusCode';
import { videoRecordConfig, getSiteVideoRecordConfig } from '@/api/mediaconfig.js';
import { validatePassword } from '@/utils/validate.js';

const ENUMSTORAGE = [
  {
    label: 'OSS',
    value: 1,
  },
  {
    label: 'FTP',
    value: 2,
  },
];

/**
 * 媒体存储方式配置
 */
const storage = {
  OSS: 1,
  FTP: 2,
};

const agreements = ['FTP', 'SFTP'];
// 协议为FTP时，存在该配置。加密方式（参数：show 显示加密  implicit 隐式加密 clear 明文）可选值（中文选项）参数传入英文
const encryptions = [
  {
    value: 'show',
    label: '加密',
  },
  {
    value: 'implicit',
    label: '隐式加密',
  },
  {
    value: 'clear',
    label: '明文',
  },
];

export default {
  name: 'InsertionSite',
  components: {},
  data() {
    const validateAdder = (rule, value, callback) => {
      if (!value) return callback('请填写FTP服务器IP: api.skysys.cn');
      const reg = /^([0-9a-zA-Z-]{1,}\.)+([a-zA-Z]{2,})$/;
      if (reg.test(value)) return callback('');
      return callback('请填写正确格式的IP地址: api.skysys.cn');
    };

    const validatePort = (rule, value, callback) => {
      if (!value) return callback('请填写端口0-65535');
      const reg = /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{4}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
      if (reg.test(value)) return callback('');
      return callback('请填写正确格式的端口0-65535');
    };

    return {
      isDisabled: false,
      SITEMODEL: SITEMODEL,
      storage: storage,
      ENUMSTORAGE,
      stId: null,
      // activeTab: 'uav',
      activeTab: 'mediaBucket',

      isSelectCPID: false,
      CPList: [],
      projectList: [],
      agreements,
      encryptions,
      userInformation: {},
      siteInfo: {
        siteID: '',
        siteName: '',
        siteAddress: '',
        siteMode: 1,
        siteLocation: '',
        siteOptionLocation: '',
        siteAltitude: '',
        siteRHHeading: '',
        siteRHHoverAltitude: '',
        siteRHAltitude: '',

        siteOptionAltitude: '',
        siteOptionHeading: '',
        siteOptionHoverAltitude: '',

        siteSafeLocation: '',

        siteFLVURL: '',
        videoPullAddr: '',
        videoPushAddr: '',
        siteSFMode: 1,
        flightWay: 1,
        PID: '',
        CPID: '',
      },

      media: {
        domainName: '',
        ossBucket: '',
      },
      rules2: {
        domainName: [{ required: true, message: '请输入播流域名', trigger: 'blur' }],
        ossBucket: [{ required: true, message: '请输入存储oss仓库', trigger: 'blur' }],
      },
      rules: {
        //  siteID: [{ required: false, validator: isSiteId, trigger: 'blur' }],
        siteName: [{ required: true, validator: ilsite, trigger: 'blur' }],
        siteAddress: [{ required: true, message: '请输入站点位置', trigger: 'blur' }],
        siteMode: [{ required: true, message: '请选择站点模式', trigger: 'change' }],
        siteLocation: [{ required: true, validator: isLnglat, trigger: 'blur' }],
        siteOptionLocation: [{ required: false, trigger: 'blur' }],
        siteSafeLocation: [{ required: false, trigger: 'blur' }],
        siteRHHoverAltitude: [{ required: true, validator: hoverAltitude, trigger: 'blur' }],
        siteRHAltitude: [{ required: true, validator: isFLoat, trigger: 'blur' }],
        siteAltitude: [{ required: true, validator: isFLoat, trigger: 'blur' }],
        siteRHHeading: [{ required: true, validator: isFLoat, trigger: 'blur' }],
        siteSFMode: [{ required: true, message: '请选择类型', trigger: 'change' }],
        CPID: [{ required: true, message: '请选择企业信息', trigger: 'change' }],
        PID: [{ required: true, message: '请选择项目信息', trigger: 'change' }],
      },

      bucketRule: {
        ftpAddr: [{ validator: validateAdder, trigger: 'blur' }],
        ftpPassword: [{ validator: validatePassword, trigger: 'blur' }],
        ftpPort: [{ validator: validatePort, trigger: 'blur' }],
      },

      storageConfig: {},

      rules3: {},

      bucketConfig: {
        ftpConfig: {},
        storageType: 2,
      },
    };
  },

  computed: {
    showPlayFlv() {
      if (this.siteInfo?.siteFLVURL) {
        return this.siteInfo.siteFLVURL;
      }

      return '';
    },
    showPushRTMP() {
      if (this.siteInfo?.sitePushURL) {
        return this.siteInfo.sitePushURL;
      }

      return '';
    },
    SDRemainingCapacity() {
      if (this.siteInfo?.UAVInfo?.RTInfo) {
        let num = this.siteInfo.UAVInfo.RTInfo.SDRemainingCapacity * 1024 * 1024; // M
        if (num == 0) return '0 B';
        var k = 1024; //设定基础容量大小
        var sizeStr = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; //容量单位
        var i = 0; //单位下标和次幂
        for (var l = 0; l < 8; l++) {
          //因为只有8个单位所以循环八次
          if (num / Math.pow(k, l) < 1) {
            //判断传入数值 除以 基础大小的次幂 是否小于1，这里小于1 就代表已经当前下标的单位已经不合适了所以跳出循环
            break; //小于1跳出循环
          }
          i = l; //不小于1的话这个单位就合适或者还要大于这个单位 接着循环
        } // 例： 900 / Math.pow(1024, 0)  1024的0 次幂 是1 所以只要输入的不小于1 这个最小单位就成立了； //     900 / Math.pow(1024, 1)  1024的1次幂 是1024  900/1024 < 1 所以跳出循环 下边的 i = l；就不会执行  所以 i = 0； sizeStr[0] = 'B'; //     以此类推 直到循环结束 或 条件成立
        return (num / Math.pow(k, i)).toFixed(3) + ' ' + sizeStr[i]; //循环结束 或 条件成立 返回字符
      }
      return '未知';
    },
    bucketMode() {
      return this.bucketConfig.storageType;
    },
  },

  created() {
    const { userInformation } = getUserInformation();
    this.getCorporationBySelf()
      .then((corporations) => {
        const cpIds = corporations?.map(({ cpId }) => cpId);
        return { corporations, cpIds };
      })
      .then(({ corporations, cpIds }) => {
        if (corporations.length > 1) {
          this.isSelectCPID = true;
          this.CPList = corporations;
        } else {
          this.isSelectCPID = false;
          this.siteInfo.CPID = corporations[0].cpId;
        }
        if (userInformation) {
          const { userEmail: email, userName: name, userAvater, userMobile: mobile, userId, roleName, idcardName } = userInformation;
          this.userInformation = { name, mobile, email, userId, roleName, idcardName };
          this.getProjectList(cpIds);
        }
        this.stId = this.$route.query.stId;
        if (this.stId) {
          this.getSiteDetail(this.stId);
          this.getVideoConfig(this.stId);
          getMediaConfig(this.stId)
            .then((response) => {
              this.bucketConfig = Object.assign({}, this.bucketConfig, response.data, { storageType: 2 });
              if(this.bucketConfig.ftpConfig === null) {
                this.bucketConfig.ftpConfig = {}
              } else {
                this.bucketConfig.httpConfig = {}
              }
            })
            .catch((err) => {
              this.$message.error('服务器内部错误，请联系管理员');
            });
        }
      });
  },

  mounted() {
    if (this.stId) {
      setTimeout(() => {
        document.getElementById('title1').innerHTML = '站点详情';
      }, 1000);
    }
  },
  methods: {
    updateMdeiaBucketConfig() {
      const { siteID, PID, CPID } = this.siteInfo;
      if (!PID && !CPID) return this.$message.warning('请先设置企业与项目信息');
      const params = { storageType: 2, ...this.bucketConfig?.ftpConfig, stId: siteID, pId: PID, cpId: CPID };
      updateMediaBucketConfig(params).then((response) => {
        if (!statusInclude(response.code)) return this.$message.error(response.reason);
        this.$message.success(response.reason);
      });
    },
    async setVideoConfig() {
      this.$refs.mediaForm.validate(async (valid) => {
        if (valid) {
          if (!this.stId) {
            return this.$message.error('请先添加或选择站点！');
          }
          let { code, data, reason } = await videoRecordConfig(this.media.domainName, this.media.ossBucket, this.stId);
          if (statusInclude(code)) {
            this.$message({ type: 'success', message: '配置成功' });
          } else {
            this.$message({ type: 'error', message: reason });
          }
        }
      });
    },
    async getVideoConfig(stId) {
      let { code, data, reason } = await getSiteVideoRecordConfig(stId);
      if (statusInclude(code)) {
        this.media.domainName = data.domainName;
        this.media.ossBucket = data.ossBucket;
      } else {
        this.$message({ type: 'error', message: '查询站点录像配置错误：' + reason });
      }
    },

    handleTabClick() {},
    async getSiteDetail(id) {
      const { code, data, reason } = await getSiteDetail(id);
      if (statusInclude(code)) {
        this.siteInfo = data;
        this.siteInfo.siteLocation = data.siteLocation.join(',');
        this.siteInfo.siteOptionLocation = data.siteOptionLocation.join(',');
        this.siteInfo.siteSafeLocation = data.siteSafeLocation.join(',');
      } else {
        this.$message.error(reason);
      }
    },

    async getCorporationBySelf(mobile = '') {
      return new Promise(async (resolve, reject) => {
        getCorporationBySelf(mobile).then((response) => {
          console.log(response);
          if (!statusInclude(response.code)) {
            reject(response);
            return this.$message.error('获取企业信息失败，请联系管理员');
          }
          resolve(response.data);
        });
      });
    },
    async getProjectList(cpIds) {
      this.projectList = [];
      const { code, data } = await queryProjectListByCpID(cpIds[0]);
      this.projectList = data?.map((project) => {
        return {
          projectId: project.pId,
          projectName: project.pName,
        };
      });
    },

    async editSiteInformation() {
      this.$refs.SiteInfo.validate(async (valid) => {
        if (valid) {
          const site = JSON.parse(JSON.stringify(this.siteInfo));
          site.siteLocation = site.siteLocation.split(',').map(Number);
          if (site.siteOptionLocation) {
            if (!isLnglatByValue(site.siteOptionLocation)) {
              this.$message.error('备降点格式错误');
              return false;
            }
            site.siteOptionLocation = site.siteOptionLocation.split(',').map(Number);
          } else {
            delete site.siteOptionLocation;
          }
          if (site.siteSafeLocation) {
            if (!isLnglatByValue(site.siteSafeLocation)) {
              this.$message.error('安全位置经纬度格式错误');
              return false;
            }
            site.siteSafeLocation = site.siteSafeLocation.split(',').map(Number);
          } else {
            delete site.siteSafeLocation;
          }

          site.siteRHHoverAltitude = parseFloat(site.siteRHHoverAltitude);
          site.siteRHAltitude = parseFloat(site.siteRHAltitude);
          site.siteAltitude = parseFloat(site.siteAltitude);
          site.siteRHHeading = parseFloat(site.siteRHHeading);

          if (!site.siteOptionAltitude) {
            delete site.siteOptionAltitude;
          } else {
            if (!isFLoatByValue(site.siteOptionAltitude)) {
              this.$message.error('无人机备降高度格式错误！');
              return false;
            }
            site.siteOptionAltitude = parseFloat(site.siteOptionAltitude);
          }
          if (!site.siteOptionHeading) {
            delete site.siteOptionHeading;
          } else {
            if (!isFLoatByValue(site.siteOptionHeading)) {
              this.$message.error('无人机备降点朝向格式错误！');
              return false;
            }
            site.siteOptionHeading = parseFloat(site.siteOptionHeading);
          }
          if (!site.siteOptionHoverAltitude) {
            delete site.siteOptionHoverAltitude;
          } else {
            if (!isFLoatByValue(site.siteOptionHoverAltitude)) {
              this.$message.error('无人机备降悬停高度格式错误！');
              return false;
            }
            site.siteOptionHoverAltitude = parseFloat(site.siteOptionHoverAltitude);
          }

          if (!this.stId) {
            var { code, reason } = await addSiteInformation(site);
          } else {
            var { code, reason } = await updateSiteInformation(site);
          }
          if (statusInclude(code)) return this.$message({ type: 'success', message: '成功' });
          this.$message({ type: 'error', message: `失败${reason}` });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
p {
  margin: 0;
  color: var(--color-primary);
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
}

.p-show {
  padding-left: 50px;
  word-break: keep-all;
}
.one {
  border-radius: 4px;
  border: 1px solid grey;
  margin-bottom: 10px;
}
.title {
  background-color: #5a9bd6;
  line-height: 40px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.content {
  padding: 10px;
}

.main {
  width: 100%;
  height: calc(100% - 40px);
  position: relative;
  overflow: auto;
  > div {
    padding: 20px;
    box-sizing: border-box;
  }
  .site {
    width: 100%;
    height: 60%;
    position: absolute;
    left: 0;
    top: 0;
    > div {
      padding: 20px;
      border-radius: 4px;
      border: 1px solid #9a9494;
      background-color: #0c172f;

      p {
        margin: 0;
        margin-bottom: 5px;
        color: white;
      }
      .el-select {
        width: 100%;
      }
      button {
        margin: auto;
      }

      .el-col {
        margin-right: 2%;
      }
      .el-col-8 {
        width: 30%;
      }
    }
  }
  .hive-uav {
    width: 100%;
    height: 40%;
    position: absolute;
    left: 0;
    bottom: 0;
    .el-row {
      margin-top: 20px;
      margin: 10px;
    }
  }
}
.tabs-content,
.el-tab-pane {
  padding: 0 10px;
  font-size: 14px;
}
.el-select {
  width: 100%;
}
::v-deep .el-form-item__label {
  text-align: right !important;
}

.btns {
  border: 0 !important;
  background-color: transparent !important;
}
</style>
